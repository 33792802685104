// import 'uikit/dist/js/uikit.js';
import UIkit from '../../node_modules/uikit/dist/js/uikit.js';
import '../scss/style.scss';

import Cookies from 'js-cookie';
import '@fortawesome/fontawesome-pro/js/all';

jQuery(document).ready(function ($) {
	/******************************
     BOTTOM SCROLL TOP BUTTON
     ******************************/

	// declare variable
	var scrollTop = $('.back-to-top');

	$('body').scroll(function () {
		// declare variable
		var topPos = $(this).scrollTop();

		// if user scrolls down - show scroll to top button
		if (topPos > 100) {
			$(scrollTop).css('opacity', '1');
		} else {
			$(scrollTop).css('opacity', '0');
		}
	}); // scroll END

	//Click event to scroll to top
	$(scrollTop).click(function () {
		$('html, body').animate(
			{
				scrollTop: 0,
			},
			800
		);
		return false;
	}); // click() scroll top EMD
	/*******************************/

	//header sticky
	$('body').scroll(function () {
		var sticky = $('#masthead'),
			scroll = $('body').scrollTop();

		if (scroll >= 300) {
			sticky.addClass('sticky');
		} else {
			sticky.removeClass('sticky');
		}
	});

	//modale selection agence
	$(function () {
		const modal = UIkit.modal('#modal-sections');
		if (modal) {
			/* 			const inFiveMinutes = new Date(
				new Date().getTime() + 5 * 60 * 1000
			); */

			if (!Cookies.get('choixagence')) {
				modal.show();
				// Cookies.set('choixagence', true, { expires: inFiveMinutes });
			}
		}
	});

	$('#modal-sections .modal-link').click((e) => {
		// e.preventDefault();
		// e.stopPropagation();
		const url = $(e.target).data('href');
		const modal = UIkit.modal('#modal-sections');
		modal.hide();
		const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
		Cookies.set('choixagence', true, { expires: inFiveMinutes });
		window.location.href = url;
	});

	//match height grille landing :

	var maxHeight = 0;

	$('.landing_grille_item .wrapper .title').each(function () {
		if ($(this).height() > maxHeight) {
			maxHeight = $(this).height();
		}
	});

	$('.landing_grille_item .wrapper .title').height(maxHeight);
});
